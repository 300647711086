<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="mobile"
      type="tel"
      :placeholder="$t('placeholder[2]')"
      :rules="[{ required: true }]"
    />
    <van-field
      v-model="captcha"
      :placeholder="$t('placeholder[3]')"
      :rules="[{ required: true }]"
    >
      <template #button>
        <van-button
          size="small"
          plain
          :disabled="!!countDown"
          native-type="button"
          @click="getCode"
        >
          {{ countDown ? countDown + 's' : $t('getCode') }}
        </van-button>
      </template>
    </van-field>
    <van-field
      v-model="password"
      type="password"
      :placeholder="$t('placeholder[1]')"
      :rules="[{ required: true }]"
    />
    <van-field
      v-model="passwd"
      type="password"
      :placeholder="$t('placeholder[4]')"
      :rules="[{ required: true }, { validator, message: $t('tips[0]') }]"
    />
    <div style="margin: 16px">
      <van-button round block type="info" native-type="submit">
        {{ $t('confirm') }}
      </van-button>
    </div>
  </van-form>
</template>
<script>
import md5 from 'js-md5'

export default {
  data() {
    return {
      mobile: '',
      captcha: '',
      password: '',
      passwd: '',
      countDown: 0,
      timer: null,
    }
  },
  methods: {
    validator(val) {
      return val === this.password
    },
    getCode() {
      if (!this.mobile) return this.$toast(this.$t('tips[1]'))
      if (this._lock) return
      this._lock = true
      this.$api
        .sendVerifyCode({ mobile: this.mobile })
        .then((res) => {
          console.log(res)
          this._lock = false
          this.countDown = 60
          clearInterval(this.timer)
          this.timer = setInterval(() => {
            if (!this.countDown) return clearInterval(this.timer)
            this.countDown--
          }, 1000)
        })
        .catch((err) => {
          this._lock = false
          this.$toast(err.msg)
        })
    },
    async onSubmit() {
      const { mobile, captcha, passwd } = this
      const { msg } = await this.$api.resetPassword({
        mobile,
        captcha,
        password: md5(passwd),
      })
      this.$dialog.alert({ message: msg }).then(() => {
        this.$router.replace('/login')
      })
    },
  },
}
</script>